import React, { Component } from 'react';

import styles from './AppInset.module.css';

class AppInset extends Component {

    render() {
        const {children} = this.props;

        return (
            <div className={styles.appInset}>
                <div className={styles.containerFull}>
                    {children}
                </div>
            </div>
        );
    }
}

export default AppInset;
