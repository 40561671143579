import React, { Component } from 'react';
import {connect} from "react-redux";
import { Link } from "@reach/router";
import mergeClassNames from 'merge-class-names'

import styles from "./CityProductCanvas.module.css";
import ProductConfiguratorImage from "../Partials/ProductConfiguratorImage";
import ProductImage from "../Partials/ProductImage";
import SnippetContent from "../Partials/SnippetContent";

import * as ActionTypes from "../../actions";

import AddButton from "../../components/AddButton";
import RemoveButton from "../../components/RemoveButton";
import ButtonPlaceholder from "../../components/ButtonPlaceholder";

const mapStateToProps = (state) => {
    return {
        facts: state.facts,
        isLoading: state.isLoading,
        parameterSet: state.parameterSet
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        executeFacts: (ruleSet, facts) => dispatch(ActionTypes.executeFacts(ruleSet, facts))
    };
};

class CityProductCanvas extends Component {

    handleRemoveProduct = (position, entryPosition) => event => {
        event.preventDefault();

        const {parameterSet, facts} = this.props;
        const {bom} = facts;

        bom.categories = bom.categories.map((value) => {

            // Remove entries if this is the right category
            if (value.id === position) {
                value.entries = value.entries.filter(v => parseInt(v.pos) !== parseInt(entryPosition));
            }

            // Remove accessories if we are removing 01-G element
            if (position === '01-G' && value.id === '04-ACC') {
                value.entries = [];
            }

            // Resort entries
            if (position === '03-Z') {
                if (value.entries) {
                    value.entries.map((v) => {
                        if (parseInt(v.pos) > parseInt(entryPosition)) {
                            v.pos = v.pos - 1;
                        }
                        return v;
                    });
                }
            }

            return value;
        });

        delete(facts['p_valid']);

        const mergedFacts = Object.assign(facts, {bom: bom, p_valid: true, p_validate_modals: true});
        this.props.executeFacts(parameterSet.ruleSet, mergedFacts);
    }

    handleInsertNoProduct = (position) => event => {
        event.preventDefault();

        const {parameterSet, facts} = this.props;
        const {bom} = facts;

        bom.categories = bom.categories.map((value) => {

            // Disable category if this is the right category
            if (value.id === position) {
                value.meta = {disabled: true};
            }

            return value;
        });

        const mergedFacts = Object.assign(facts, {bom: bom, p_valid: true, p_validate_modals: true});
        this.props.executeFacts(parameterSet.ruleSet, mergedFacts);
    }

    handleRemoveNoProduct = (position) => event => {
        event.preventDefault();

        const {parameterSet, facts} = this.props;
        const {bom} = facts;

        bom.categories = bom.categories.map((value) => {

            // Enable category if this is the right category
            if (value.id === position) {
                value.meta = {disabled: false};
            }

            return value;
        });

        const mergedFacts = Object.assign(facts, {bom: bom, p_valid: true, p_validate_modals: true});
        this.props.executeFacts(parameterSet.ruleSet, mergedFacts);
    }

    getPlaceholderClassName() {
        const {view} = this.props;

        if (view) {
            switch (view.path) {
                case 'ce180_produktansicht':
                    return mergeClassNames([styles.canvasPlaceholder, styles.canvasPlaceholderCE180]);
                case 'ce200_produktansicht':
                    return mergeClassNames([styles.canvasPlaceholder, styles.canvasPlaceholderCE200]);
                case 'ce230_produktansicht':
                    return mergeClassNames([styles.canvasPlaceholder, styles.canvasPlaceholderCE230]);
                default:
                    return styles.canvasPlaceholder;
            }
        }

        return styles.canvasPlaceholder;
    }

    getPlaceholderImage() {
        const {view} = this.props;

        if (view) {
            switch (view.path) {
                case 'ce180_produktansicht':
                    return require('../../assets/ce180-placeholder.png');
                case 'ce200_produktansicht':
                    return require('../../assets/ce200-placeholder.png');
                case 'ce230_produktansicht':
                    return require('../../assets/ce230-placeholder.png');
                default:
                    return;
            }
        }
    }

    getClassNameByCategory(category) {
        switch (category) {
            case '01-G':
                return mergeClassNames([styles.position, styles.positionG]);
            case '02-A':
                return mergeClassNames([styles.position, styles.positionA]);
            case '03-Z':
                return mergeClassNames([styles.position, styles.positionZ]);
            case '04-ACC':
                return mergeClassNames([styles.position, styles.positionACC]);
            default:
                return '';
        }
    }

    getCurrentHeight() {
        const {facts} = this.props;
        const {bom} = facts;

        let currentHeight = 0;

        if (bom && bom.categories) {
            bom.categories.forEach((value) => {
                if (value.id === '02-A' || value.id === '03-Z') {
                    if (value.entries) {
                        value.entries.forEach((entry) => {
                            if (entry && entry.quantity > 0 && entry.meta.inUse && entry.meta.isAccessory !== true) {
                                currentHeight += entry.meta.height;
                            }
                        });
                    }
                }
            });
        }

        return currentHeight;
    }

    getStyleByCategory(category) {
        switch (category) {
            case '01-G':
                const currentHeight = this.getCurrentHeight();
                const GEntryHeight = currentHeight / 3 + 8;

                return {top: GEntryHeight + 'px'};
            default:
                return;
        }
    }

    hasElementsOfCategory(category) {
        const {facts} = this.props;
        const {bom} = facts;

        let hasElements = false;

        if (bom && bom.categories) {
            bom.categories.forEach((value) => {
                if (value.id === category) {
                    if (value.entries && value.entries[0]) {
                        hasElements = true
                    }
                }
            });
        }

        return hasElements;
    }

    isValidModal(modal) {
        const {facts} = this.props;
        const validModals = facts.p_valid_modals;

        if (validModals && validModals[modal] && validModals[modal] > 0) {
            return true;
        }

        return false;
    }

    hasValidModals(requirements) {
        let i = 0;

        requirements.forEach((value) => {
            if (this.isValidModal(value.externalid)) {
                i++;
            }
        });

        if (i > 0) {
            return true;
        }

        return false;
    }

    render() {
        const {view, facts, enableButtons, disableRefetch} = this.props;
        const {bom} = facts;

        let nextZEntryPosition = 0;
        if (bom.categories) {
            bom.categories.forEach((value) => {
                if (value.id === '03-Z' && value.entries) {
                    value.entries.forEach((entry) => {
                        if (entry && entry.quantity > 0 && entry.meta.inUse && entry.meta.isAccessory !== true) {
                            nextZEntryPosition++;
                        }
                    });
                }
            });
        }

        return (
            <div className={styles.canvas}>
                <div className={styles.canvasImage}>
                    {(this.hasElementsOfCategory('02-A') || this.hasElementsOfCategory('01-G')) ? (
                        <></>
                    ) : (
                        <div className={this.getPlaceholderClassName()}>
                            <img src={this.getPlaceholderImage()} alt="" />
                        </div>
                    )}

                    {bom && bom.categories && bom.categories.map((category) => (
                        <div className={this.getClassNameByCategory(category.id)} key={category.id} style={this.getStyleByCategory(category.id)}>
                            {category.id === '01-G' && (
                                <>
                                    {(category.entries && category.entries[0]) ? (
                                        <>
                                            {category.entries.map((value, i) => (
                                                <div key={i}>
                                                    {value?.id && value?.meta.isAccessory !== true && (
                                                        <div className={styles.positionGImageWrap}>
                                                            <ProductConfiguratorImage id={value.id} disableRefetch={disableRefetch} />

                                                            {enableButtons && (
                                                                <RemoveButton onClick={this.handleRemoveProduct(category.id, 0)} />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {view.activities && view.activities.map((value) => (
                                                <>
                                                    {value.activitytype === 'ce_open_modal_GE' && (
                                                        <div className={styles.positionGAddButton}>
                                                            {this.hasValidModals(value.requirements) && (
                                                                <>
                                                                    {enableButtons ? (
                                                                        <AddButton>
                                                                            {value.requirements.map((v) => (
                                                                                <>
                                                                                    {this.isValidModal(v.externalid) && (
                                                                                        <div key={v.externalid + '/' + category.id}>
                                                                                            <Link to={v.externalid + '/' + category.id + '/0'}>
                                                                                                <SnippetContent id={"p_" + v.externalid} fallback={v.name} />
                                                                                            </Link>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </AddButton>
                                                                    ) : (
                                                                        <ButtonPlaceholder />
                                                                    )}
                                                                </>

                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}

                            {category.id === '02-A' && (
                                <>
                                    {(category.entries && category.entries[0]) ? (
                                        <div>
                                            {category.entries.map((value, i) => (
                                                <div className={styles.entry} key={i}>
                                                    {value?.id && value?.meta.isAccessory !== true && (
                                                        <div>
                                                            <ProductConfiguratorImage id={value.id} disableRefetch={disableRefetch} />

                                                            {enableButtons && ( ! this.hasElementsOfCategory('01-G')) && (
                                                                <RemoveButton onClick={this.handleRemoveProduct(category.id, 0)} />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div>
                                            {view.activities && view.activities.map((value) => (
                                                <>
                                                    {value.activitytype === 'ce_open_modal_AE' && (
                                                        <div className={styles.positionAAddButton}>
                                                            {this.hasValidModals(value.requirements) && (
                                                                <>
                                                                    {enableButtons ? (
                                                                        <AddButton>
                                                                            {value.requirements.map((v) => (
                                                                                <>
                                                                                    {this.isValidModal(v.externalid) && (
                                                                                        <div key={v.externalid + '/' + category.id}>
                                                                                            <Link to={v.externalid + '/' + category.id + '/0'}>
                                                                                                <SnippetContent id={"p_" + v.externalid} fallback={v.name} />
                                                                                            </Link>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </AddButton>
                                                                    ) : (
                                                                        <ButtonPlaceholder />
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}

                            {category.id === '03-Z' && (
                                <>
                                    {(category.entries && category.entries[0]) && (
                                        <div>
                                            {category.entries.map((value, i) => (
                                                <div className={styles.entry} key={i}>
                                                    {(value?.id && value?.meta.isAccessory !== true) && (
                                                        <div>
                                                            {value.meta.dynamicHeight ? (
                                                                <div className={styles.dynamicPosition} style={{height: (value.meta.height / 3) + 'px'}}>
                                                                    <ProductConfiguratorImage id={value.id} disableRefetch={disableRefetch} />
                                                                </div>
                                                            ) : (
                                                                <ProductConfiguratorImage id={value.id} disableRefetch={disableRefetch} />
                                                            )}

                                                            {enableButtons && ( ! this.hasElementsOfCategory('01-G')) && (
                                                                <RemoveButton onClick={this.handleRemoveProduct(category.id, value.pos)} />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {category.meta?.disabled === true ? (
                                        <>
                                            {enableButtons && (
                                                <div className={styles.positionZAddButton}>
                                                    <RemoveButton onClick={this.handleRemoveNoProduct(category.id)} />
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {view.activities && view.activities.map((value) => (
                                                <>
                                                    {value.activitytype === 'ce_open_modal_ZE' && (
                                                        <div className={styles.positionZAddButton}>
                                                            {(this.hasValidModals(value.requirements) && this.hasElementsOfCategory('02-A')) && (
                                                                <>
                                                                    {enableButtons ? (
                                                                        <AddButton>
                                                                            {value.requirements.map((v) => (
                                                                                <div key={v.path + '/' + category.id}>
                                                                                    {(
                                                                                        v.path === "ce180_kein_zwischenelement" ||
                                                                                        v.path === "ce200_kein_zwischenelement" ||
                                                                                        v.path === "ce230_kein_zwischenelement"
                                                                                    ) ? (
                                                                                        <a href="/" onClick={this.handleInsertNoProduct(category.id)}>
                                                                                            <SnippetContent id={"p_re_kein_einsatz"} fallback={v.name} />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <>
                                                                                            {this.isValidModal(v.externalid) && (
                                                                                                <div key={v.externalid + '/' + category.id}>
                                                                                                    <Link to={v.externalid + '/' + category.id + '/' + nextZEntryPosition}>
                                                                                                        <SnippetContent id={"p_" + v.externalid} fallback={v.name} />
                                                                                                    </Link>
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            ))}
                                                                        </AddButton>
                                                                    ) : (
                                                                        <ButtonPlaceholder />
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </>
                                    )}
                                </>
                            )}

                            {category.id === '04-ACC' && (
                                <>
                                    {(category.entries && category.entries[0]) ? (
                                        <div>
                                            {category.entries.map((value, i) => (
                                                <div className={styles.entry} key={i}>
                                                    {(value?.id && value?.meta.isAccessory !== true) && (
                                                        <div>
                                                            <ProductImage id={value.id} disableRefetch={disableRefetch} />

                                                            {enableButtons && (
                                                                <RemoveButton onClick={this.handleRemoveProduct(category.id, value.pos)} />
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (

                                        <>
                                            {category.meta?.disabled === true ? (
                                                <>
                                                    {enableButtons && (
                                                        <div className={styles.positionZAddButton}>
                                                            <RemoveButton onClick={this.handleRemoveNoProduct(category.id)} />
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {view.activities && view.activities.map((value) => (
                                                        <>
                                                            {value.activitytype === 'ce_open_modal_ACC' && (
                                                                <div className={styles.positionZAddButton}>
                                                                    {(this.hasValidModals(value.requirements) && this.hasElementsOfCategory('01-G')) && (
                                                                        <>
                                                                            {enableButtons ? (
                                                                                <AddButton>
                                                                                    {value.requirements.map((v) => (
                                                                                        <div key={v.path + '/' + category.id}>
                                                                                            {(
                                                                                                v.path === "ce180_zubehoer_kein_erdstueck" ||
                                                                                                v.path === "ce200_zubehoer_kein_erdstueck" ||
                                                                                                v.path === "ce230_zubehoer_kein_erdstueck"
                                                                                            ) ? (
                                                                                                <a href="/" onClick={this.handleInsertNoProduct(category.id)}>
                                                                                                    <SnippetContent id={"p_" + v.path} fallback={v.name} />
                                                                                                </a>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {this.isValidModal(v.externalid) && (
                                                                                                        <div key={v.externalid + '/' + category.id}>
                                                                                                            <Link to={v.externalid + '/' + category.id + '/0'}>
                                                                                                                <SnippetContent id={"p_" + v.externalid} fallback={v.name} />
                                                                                                            </Link>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    ))}
                                                                                </AddButton>
                                                                            ) : (
                                                                                <ButtonPlaceholder />
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                            )}
                                                        </>
                                                    ))}
                                                </>
                                            )}
                                        </>

                                    )}

                                </>
                            )}
                        </div>
                    ))}
                </div>

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityProductCanvas);
