import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Router} from "@reach/router";
import qs from 'qs';

import * as ActionTypes from '../actions';

import styles from './App.module.css';

import Home from './Home';
import Configurator from './Configurator';
import SnippetContent from './Partials/SnippetContent';
import SnippetLink from './Partials/SnippetLink';
import LoadingSpinner from "../components/LoadingSpinner";

const mapStateToProps = (state) => {
    return {
        locale: state.locale,
        isBooting: state.isBooting,
        isLoading: state.isLoading,
        hasErrors: state.hasErrors
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        switchLocale: (locale) => dispatch(ActionTypes.switchLocale(locale))
    };
};

class App extends Component {

    handleSwitchLocale = (locale) => event => {
        event.preventDefault();
        this.props.switchLocale(locale);
    }

    componentDidMount() {
        const queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (queryString.locale) {
            this.props.switchLocale(queryString.locale);
        }

        const href = window.location.href;
        const search = window.location.search;
        const urlWithoutParams = href.replace(search, '');
        window.history.pushState({}, '', urlWithoutParams);
    }

    render() {
        const {isBooting, isLoading, locale} = this.props;

        return (
            <div className={styles.app}>
                <div className="d-print-none">
                    <nav className={styles.appLocale}>
                        <a href="/" className={locale === 'de' ? styles.appLocaleActive : undefined} onClick={this.handleSwitchLocale('de')}><span>DE</span></a>
                        <a href="/" className={locale === 'en' ? styles.appLocaleActive : undefined} onClick={this.handleSwitchLocale('en')}><span>EN</span></a>
                        <a href="/" className={locale === 'fr' ? styles.appLocaleActive : undefined} onClick={this.handleSwitchLocale('fr')}><span>FR</span></a>
                    </nav>
                </div>

                <div className={styles.appLogo}>
                    <a href="https://www.hess.eu/" target="_blank" rel="noopener noreferrer"><img alt="Hess" src={require('../assets/logo.svg')} /></a>
                </div>
                {isBooting ? (
                    <div className={styles.appStatus}>
                        <LoadingSpinner />
                    </div>
                ) : (
                    <div>
                        {isLoading && (
                            <div className={styles.appStatus}>
                                <LoadingSpinner />
                            </div>
                        )}

                        <Router>
                            <Home path="/" />
                            <Configurator path=":configuratorId/*" />
                        </Router>
                    </div>
                )}

                {isLoading ? (
                    <></>
                ) : (
                    <footer className="site-footer pt-4 pb-1_5 text-center text-white small">
                        <div className="container">
                            <SnippetLink id="p_link_privacy_policy" fallback="https://www.hess.eu/de/datenschutz" className="d-block d-md-inline-block link-reset link-underline-block py-0_25 mb-0_25 px-md-0_5 mx-md-0_5 mb-md-0">
                                <span><SnippetContent id="p_label_privacy_policy" fallback="Datenschutz" /></span>
                            </SnippetLink>
                            <SnippetLink id="p_link_imprint" fallback="https://www.hess.eu/de/impressum" className="d-block d-md-inline-block link-reset link-underline-block py-0_25 mb-0_25 px-md-0_5 mx-md-0_5 mb-md-0">
                                <span><SnippetContent id="p_label_imprint" fallback="Impressum" /></span>
                            </SnippetLink>
                        </div>
                    </footer>
                )}

            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
