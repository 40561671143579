export const SWITCH_CONFIGURATOR = 'SWITCH_CONFIGURATOR';

export function switchConfiguratorAction(payload) {
    return {
        type: SWITCH_CONFIGURATOR,
        payload: payload
    }
}

export function switchConfigurator(configuratorId) {
    return (dispatch) => {
        dispatch(switchConfiguratorAction(configuratorId));
    }
}
