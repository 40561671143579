import * as ActionTypes from './index';

export const SWITCH_LOCALE = 'SWITCH_LOCALE';

export function switchLocaleAction(payload) {
    return {
        type: SWITCH_LOCALE,
        payload: payload
    }
}

export function switchLocale(locale) {
    return (dispatch) => {
        dispatch(switchLocaleAction(locale));
        dispatch(ActionTypes.fetchSnippets());
    }
}
