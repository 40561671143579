import React, { Component } from 'react';

import styled from 'styled-components';
import styles from './Navigator.module.css';

class Navigator extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: 0,
            height: 0,
            documentHeight: 0,
            scrollOffset: 0
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        window.addEventListener('scroll', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('scroll', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight,
            documentHeight: document.body.clientHeight,
            scrollOffset: window.scrollY
        });
    }

    render() {
        const {children} = this.props;

        const StyledNavigator = styled.div`
            position: fixed;
            left: 20px;
            bottom: 20px;
            width: 140px;
            height: ${this.state.documentHeight / 10}px;
            background: #000;
            border: 1px solid #888;
        `;

        const StyledScreen = styled.div`
            position: absolute;
            z-index: 50;
            top: ${this.state.scrollOffset / 10.1}px;
            left: 0;
            right: 0;
            width: 100%;
            height: ${this.state.height / 10}px;
            outline: 2px solid #e22018;
        `;

        return (
            <div className={styles.navigator}>
                <StyledNavigator>
                    <StyledScreen />
                </StyledNavigator>
                <div className={styles.image}>
                    {children}
                </div>
            </div>
        );
    }
}

export default Navigator;
