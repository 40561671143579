import { Component } from 'react';

class Value extends Component {

    valuesReducer = value => (accumulator, currentValue) => {
        if( ! accumulator) {
            accumulator = [];
        }
        accumulator.push(currentValue.value);

        return accumulator;
    };

    getValues(value) {
        return value?.reduce(this.valuesReducer(value), null);
    }

    render() {
        const {value} = this.props;

        switch (value.valuetype) {
            case "VALUESET":
                return this.getValues(value.value).join(', ');
            case "TEXT":
            default:
                return value.value.value;
        }
    }
}

export default Value;
