import * as ConfigurationService from '../services/ConfigurationService'

export const FETCH_PARAMETERSET_REQUEST = 'FETCH_PARAMETERSET_REQUEST';
export const FETCH_PARAMETERSET_SUCCESS = 'FETCH_PARAMETERSET_SUCCESS';
export const FETCH_PARAMETERSET_FAILURE = 'FETCH_PARAMETERSET_FAILURE';

export function fetchParameterSetRequest() {
    return {
        type: FETCH_PARAMETERSET_REQUEST
    }
}

export function fetchParameterSetSuccess(payload) {
    return {
        type: FETCH_PARAMETERSET_SUCCESS,
        payload: payload
    }
}

export function fetchParameterSetFailure(payload) {
    return {
        type: FETCH_PARAMETERSET_FAILURE,
        payload: payload
    }
}

export function fetchParameterSet(params) {
    return (dispatch, getState) => {
        dispatch(fetchParameterSetRequest());

        const state = getState();
        const locale = state.locale;

        ConfigurationService.fetchParameterSet(params, locale)
            .then(response => {
                dispatch(fetchParameterSetSuccess(response));
            })
            .catch(error => {
                dispatch(fetchParameterSetFailure(error));
            });
    }
}
