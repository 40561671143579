import React, { Component } from 'react';
import {connect} from "react-redux";
import { Link } from "@reach/router";
import mergeClassNames from 'merge-class-names'

import styles from "./RenoProductCanvas.module.css";
import ProductConfiguratorAccessoryImage from "../Partials/ProductConfiguratorAccessoryImage";
import ProductConfiguratorImage from "../Partials/ProductConfiguratorImage";
import SnippetContent from "../Partials/SnippetContent";

import * as ActionTypes from "../../actions";

import AddButton from "../../components/AddButton";
import RemoveButton from "../../components/RemoveButton";
import ButtonPlaceholder from "../../components/ButtonPlaceholder";

const mapStateToProps = (state) => {
    return {
        facts: state.facts,
        isLoading: state.isLoading,
        parameterSet: state.parameterSet
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        executeFacts: (ruleSet, facts) => dispatch(ActionTypes.executeFacts(ruleSet, facts))
    };
};

class RenoProductCanvas extends Component {

    handleRemoveProduct = (position) => event => {
        event.preventDefault();

        const {parameterSet, facts} = this.props;
        const {bom} = facts;

        bom.categories = bom.categories.map((value) => {
            if (value.id === position) {
                const meta = value.entries[0].meta;

                let entries = [];

                let entry = {quantity: 0, meta: {...meta, inUse: false}};
                entries.push(entry);

                value.entries = entries;
            }
            return value;
        });

        delete(facts['p_valid']);

        const mergedFacts = Object.assign(facts, {bom: bom, p_valid: true});
        this.props.executeFacts(parameterSet.ruleSet, mergedFacts);
    }

    handleInsertNoProduct = (position) => event => {
        event.preventDefault();

        const {parameterSet, facts} = this.props;
        const {bom} = facts;

        bom.categories = bom.categories.map((value) => {
            if (value.id === position) {
                const meta = value.entries[0].meta;
                let entries = [];

                let entry = {quantity: 1, meta: {...meta, inUse: true, orientation: 'all', isPlaceholder: true}};
                entries.push(entry);

                value.entries = entries;
            }
            return value;
        });

        delete(facts['p_valid']);

        const mergedFacts = Object.assign(facts, {bom: bom, p_valid: true});
        this.props.executeFacts(parameterSet.ruleSet, mergedFacts);
    }

    getClassNameByCategory(category) {
        switch (category) {
            case '01-S':
                return mergeClassNames([styles.position, styles.positionS]);
            case '02-A':
                return mergeClassNames([styles.position, styles.positionA]);
            case '03-F1B1':
                return mergeClassNames([styles.position, styles.positionFB, styles.positionF1B1]);
            case '04-F2B2':
                return mergeClassNames([styles.position, styles.positionFB, styles.positionF2B2]);
            case '05-F3B3':
                return mergeClassNames([styles.position, styles.positionFB, styles.positionF3B3]);
            case '06-F4B4':
                return mergeClassNames([styles.position, styles.positionFB, styles.positionF4B4]);
            case '07-F5B5':
                return mergeClassNames([styles.position, styles.positionFB, styles.positionF5B5]);
            case '08-ACC':
                return mergeClassNames([styles.position, styles.positionACC]);
            default:
                return '';
        }
    }

    getClassNameByOrientation(orientation) {
        const {closePositioning} = this.props;

        switch (orientation) {
            case 'front':
                return mergeClassNames([styles.canvas, styles.canvasFront, closePositioning && styles.canvasFrontClose]);
            case 'back':
                return mergeClassNames([styles.canvas, styles.canvasBack, closePositioning && styles.canvasBackClose]);
            default:
                return styles.canvas;
        }
    }

    render() {
        const {view, facts, orientation, enableButtons, hideButtons, showOrientation, disableRefetch} = this.props;
        const {bom} = facts;

        return (
            <div className={this.getClassNameByOrientation(orientation)}>
                {showOrientation && (
                    <div className={styles.canvasOrientation}>
                        <SnippetContent id={'p_re_pos_' + orientation} fallback="" />
                    </div>
                )}
                <div className={styles.canvasImage}>
                    {bom.categories.map((category) => (
                        <div className={this.getClassNameByCategory(category.id)} key={category.id}>
                            <div>
                                {category.entries && (
                                    <div>
                                        {category.entries.map((value, i) => (
                                            <div key={i}>
                                                {(
                                                    category.id === '03-F1B1' ||
                                                    category.id === '04-F2B2' ||
                                                    category.id === '05-F3B3' ||
                                                    category.id === '06-F4B4' ||
                                                    category.id === '07-F5B5'
                                                ) ? (
                                                    <>
                                                        {value?.id && value?.meta.orientation === orientation && value?.meta.isAccessory !== true && (
                                                            <div>
                                                                <ProductConfiguratorImage id={value.id} disableRefetch={disableRefetch} />
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {value?.id && (
                                                            <div>
                                                                {category.id === '08-ACC' ? (
                                                                    <ProductConfiguratorAccessoryImage id={value.id} disableRefetch={disableRefetch} />
                                                                ) : (
                                                                    <ProductConfiguratorImage id={value.id} disableRefetch={disableRefetch} />
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                { ! hideButtons && (
                    <div className={styles.canvasButtons}>
                        {bom.categories.map((category) => (
                            <div className={this.getClassNameByCategory(category.id)} key={category.id}>
                                <div>
                                    {view.activities && (
                                        <div>
                                            {view.activities.map((value) => (
                                                <div key={value.name}>
                                                    {(
                                                        (category.id === '02-A' && value.activitytype === 'open_modal_A') ||
                                                        ((
                                                            category.id === '03-F1B1' ||
                                                            category.id === '04-F2B2' ||
                                                            category.id === '05-F3B3' ||
                                                            category.id === '06-F4B4' ||
                                                            category.id === '07-F5B5'
                                                        ) && value.activitytype === 'open_modal_F/B') ||
                                                        (category.id === '08-ACC' && value.activitytype === 'open_modal_ACC')
                                                    ) && (
                                                        <div className={styles.buttons}>
                                                            {category.entries[0].quantity ? (
                                                                <>
                                                                    {(category.entries[0].meta.orientation === orientation || category.entries[0].meta.orientation === 'all') && (
                                                                        <>
                                                                            {enableButtons && (
                                                                                <RemoveButton onClick={this.handleRemoveProduct(category.id)} />
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {enableButtons ? (
                                                                        <AddButton>
                                                                            {value.requirements.map((v) => (
                                                                                <div key={v.path + '/' + category.id}>
                                                                                    {v.path === "re_kein_einsatz" ? (
                                                                                        <a href="/" onClick={this.handleInsertNoProduct(category.id)}>
                                                                                            <SnippetContent id={"p_" + v.externalid} fallback={v.name} />
                                                                                        </a>
                                                                                    ) : (
                                                                                        <>
                                                                                            {(
                                                                                                category.id === '03-F1B1' ||
                                                                                                category.id === '04-F2B2' ||
                                                                                                category.id === '05-F3B3' ||
                                                                                                category.id === '06-F4B4' ||
                                                                                                category.id === '07-F5B5'
                                                                                            ) ? (
                                                                                                <Link to={v.path + '/' + category.id + '/0/' + orientation}>
                                                                                                    <SnippetContent id={"p_" + v.externalid} fallback={v.name} />
                                                                                                </Link>
                                                                                            ) : (
                                                                                                <Link to={v.path + '/' + category.id + '/0/all'}>
                                                                                                    <SnippetContent id={"p_" + v.externalid} fallback={v.name} />
                                                                                                </Link>
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            ))}
                                                                        </AddButton>
                                                                    ) : (
                                                                        <ButtonPlaceholder />
                                                                    )}
                                                                </>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenoProductCanvas);
