import React, { Component } from 'react';
import {connect} from "react-redux";

import styles from "./RenoResultImage.module.css";

import RenoProductCanvas from "../Canvas/RenoProductCanvas";

const mapStateToProps = (state) => {
    return {
        facts: state.facts
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

class RenoResultImage extends Component {

    render() {
        const {facts} = this.props;

        return (
            <div className={styles.renoResultImage} style={{height: (facts['p_profilhoehe'] / 3 + 300) + 'px'}}>
                <RenoProductCanvas view={false} closePositioning={true} orientation={'front'} showOrientation={true} enableButtons={false} hideButtons={false} disableRefetch={false} />
                <RenoProductCanvas view={false} closePositioning={true} orientation={'back'} showOrientation={true} enableButtons={false} hideButtons={false} disableRefetch={false} />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenoResultImage);
