import React, { Component } from 'react';

import SettingsView from './SettingsView';
import RenoProductView from './RenoProductView';
import CityProductView from './CityProductView';
import ResultView from './ResultView';

class View extends Component {

    components = {
        SettingsView: SettingsView,
        RenoProductView: RenoProductView,
        CityProductView: CityProductView,
        ResultView: ResultView
    }

    render() {
        const {view, views, navigate} = this.props;
        const ViewName = this.components[view['RuleEngine:attr_ui_class']];

        return (
            <ViewName views={views} view={view} navigate={navigate} />
        );
    }
}

export default View;
