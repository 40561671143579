import React, { Component } from 'react';
import {connect} from "react-redux";
import { Link } from "@reach/router";

import styles from "./NewView.module.css";

import * as ActionTypes from "../../actions";

import SnippetContent from '../Partials/SnippetContent';
import SnippetInfoIcon from '../Partials/SnippetInfoIcon';

const mapStateToProps = (state) => {
    return {
        activeConfiguratorId: state.configuratorId,
        parameterSet: state.parameterSet,
        facts: state.facts,
        isLoading: state.isLoading,
        resumePaths: state.resumePaths
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        reset: () => dispatch(ActionTypes.reset()),
        switchConfigurator: (configuratorId) => dispatch(ActionTypes.switchConfigurator(configuratorId))
    };
};

class NewView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            toggled: false
        };
    }

    handleReset = event => {
        this.props.reset();
        this.props.switchConfigurator(this.props.configuratorId);
    }

    handleToggleTypes = event => {
        const {toggled} = this.state;
        this.setState({
            toggled: ! toggled
        })
    }

    getTypeFromExternalId(externalId) {
        switch (externalId) {
            case 'ce180_grundeinstellungen':
                return '180';
            case 'ce200_grundeinstellungen':
                return '200';
            case 'ce230_grundeinstellungen':
                return '230';
            default:
                return externalId;
        }
    }

    render() {
        const {facts, views, configuratorId, activeConfiguratorId, parameterSet, resumePaths, isLoading} = this.props;
        const {toggled} = this.state;
        const {bom} = facts;

        return (
            <div className={styles.newView}>
                {views[0] && (
                    <section className="section section-type-hero section-type-hero-product text-white">
                        <div className="position-relative text-center">
                            {isLoading ? (
                                <></>
                            ) : (
                                <div className="content content-product-hero text-left">
                                    <div className="container">
                                        <div className="row align-items-center justify-content-end">
                                            <div className="col-12 col-md-6 pt-1 pb-1">
                                                {toggled ? (
                                                    <div onClick={this.handleToggleTypes} className={styles.backLink}>
                                                        <i className="fa far fa-angle-left"></i> <SnippetContent id="p_label_back" fallback="Zurück" />
                                                    </div>
                                                ) : (
                                                    <Link to={'/'} className={styles.backLink}>
                                                        <i className="fa far fa-angle-left"></i> <SnippetContent id="p_label_all_configurators" fallback="Alle Konfiguratoren" />
                                                    </Link>
                                                )}
                                                <h1 className="headline">{parameterSet.name}</h1>

                                                {toggled ? (
                                                    <>
                                                    {parameterSet.parametergroups.map((value) => (
                                                        <>
                                                            <Link className="btn btn-primary" onClick={this.handleReset} to={value.externalid}>
                                                                <SnippetContent id={"p_ce_headline_" + this.getTypeFromExternalId(value.externalid)} fallback={"City Elements " + this.getTypeFromExternalId(value.externalid)} />
                                                                <SnippetInfoIcon id={"p_ce_diameter_" + this.getTypeFromExternalId(value.externalid)} />
                                                            </Link>
                                                            <br />
                                                        </>
                                                    ))}
                                                    </>
                                                ) : (
                                                    <>
                                                        {(parameterSet.parametergroups.length === 1) ? (
                                                            <>
                                                                {parameterSet.parametergroups.map((value) => (
                                                                    <Link className="btn btn-primary" onClick={this.handleReset} to={value.externalid}>
                                                                        <i className="fa far fa-plus"></i> <SnippetContent id="p_button_new_configuration" fallback="Neue Konfiguration" />
                                                                    </Link>
                                                                ))}
                                                            </>
                                                        ) : (
                                                            <button className="btn btn-primary" onClick={this.handleToggleTypes}>
                                                                <i className="fa far fa-plus"></i> <SnippetContent id="p_button_new_configuration" fallback="Neue Konfiguration" />
                                                            </button>
                                                        )}

                                                        {(bom && configuratorId === activeConfiguratorId) && (
                                                            <Link className="btn btn-outline-white" to={resumePaths.configuration}>
                                                                <SnippetContent id="p_button_resume" fallback="Konfiguration fortsetzen" />
                                                            </Link>
                                                        )}
                                                    </>
                                                )}

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <div className="position-relative d-block">
                                <img className="img-max" src={require('../../assets/header-' + configuratorId + '.png')} alt="" />
                            </div>
                        </div>
                    </section>
                )}
            </div>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewView);
