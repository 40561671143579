import * as ConfigurationService from '../services/ConfigurationService'

export const FETCH_ACCESSORIES_REQUEST = 'FETCH_ACCESSORIES_REQUEST';
export const FETCH_ACCESSORIES_SUCCESS = 'FETCH_ACCESSORIES_SUCCESS';
export const FETCH_ACCESSORIES_FAILURE = 'FETCH_ACCESSORIES_FAILURE';

export function fetchAccessoriesRequest() {
    return {
        type: FETCH_ACCESSORIES_REQUEST
    }
}

export function fetchAccessoriesSuccess(payload) {
    return {
        type: FETCH_ACCESSORIES_SUCCESS,
        payload: payload
    }
}

export function fetchAccessoriesFailure(payload) {
    return {
        type: FETCH_ACCESSORIES_FAILURE,
        payload: payload
    }
}

export function fetchAccessories(productId, accessoryProductGroupId) {
    return (dispatch, getState) => {
        dispatch(fetchAccessoriesRequest());

        const state = getState();
        const locale = state.locale;

        ConfigurationService.fetchAccessories(productId, accessoryProductGroupId, locale)
            .then(response => {
                dispatch(fetchAccessoriesSuccess(response));
            })
            .catch(error => {
                dispatch(fetchAccessoriesFailure(error));
            });
    }
}
