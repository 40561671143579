import React, { Component } from 'react';
import ReactSlider from 'react-slider'

import styles from './SliderSelect.module.css';

class SliderSelect extends Component {

    renderThumb = (props, state) => {
        const {selections} = this.props;
        const selectionsArray = Object.entries(selections);
        const selectionValue = selectionsArray[state.valueNow];

        return selectionValue && (
            <div {...props}>{selectionValue[1]}</div>
        )
    }

    handleOnChange = (value) => {
        const {selections, onChange} = this.props;
        const selectionsArray = Object.entries(selections);
        const selectionValue = selectionsArray[value];

        // The onChange handler expects an input-event ...
        onChange({target: {value: selectionValue[0]}});
    }

    selectionReducer = selectedValue => (accumulator, currentValue, currentIndex) => {
        if (currentValue[0] === selectedValue) {
            accumulator = currentIndex;
        }

        return accumulator;
    };

    render() {
        const {selections, selectedValue} = this.props;
        const selectionsArray = Object.entries(selections);

        const selectionIndex = selectionsArray.reduce(this.selectionReducer(selectedValue), null);

        return selectionIndex !== null && (
            <div className={styles.sliderWrap}>
                <ReactSlider
                    className={styles.slider}
                    thumbClassName={styles.sliderThumb}
                    trackClassName={styles.sliderTrack}
                    min={0}
                    max={selectionsArray.length - 1}
                    defaultValue={selectionIndex}
                    onAfterChange={this.handleOnChange}
                    renderThumb={this.renderThumb}
                    />
            </div>
        );
    }
}

export default SliderSelect;
