import React, { Component } from 'react';

import styles from './Parameter.module.css';

import TextSelect from './Selects/TextSelect';
// import TextWithImageSelect from './Selects/TextWithImageSelect';
import ImageSelect from './Selects/ImageSelect';
import ImageSelectTiny from './Selects/ImageSelectTiny';
import ToggleSelect from './Selects/ToggleSelect';
import SliderSelect from './Selects/SliderSelect';

import SnippetContent from '../containers/Partials/SnippetContent';
import SnippetInfoIcon from '../containers/Partials/SnippetInfoIcon';

class Parameter extends Component {

    components = {
        TextSelect: TextSelect,
        TextWithImageSelect: ImageSelectTiny,
        ImageSelect: ImageSelect,
        ToggleSelect: TextSelect,
        SliderSelect: SliderSelect
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedValue: false
        };
    }

    componentDidMount() {
        const {parameter, selectedValue} = this.props;

        if (parameter.parameterType === "BOOLEAN") {
            if (selectedValue === true || selectedValue === false) {
                this.setState({
                    selectedValue: selectedValue
                });
            }
            else if (parameter['RuleEngine:attr_default']) {
                this.setState({
                    selectedValue: false
                });
            }
        }
        else {
            if (selectedValue) {
                this.setState({
                    selectedValue: selectedValue
                });
            }
            else if (parameter['RuleEngine:attr_default']) {
                this.setState({
                    selectedValue: parameter['RuleEngine:attr_default']
                });
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {selectedValue} = this.props;

        if (prevProps.selectedValue && prevState.selectedValue !== selectedValue) { // Selected value has changed ...
            this.setState({
                selectedValue: selectedValue
            });
        }
    }

    handleSelection = event => {
        const value = event.target.value;
        this.setState({
            selectedValue: value
        });
        if (this.props.onChangeSelection) {
            this.props.onChangeSelection(this.props.parameter.externalid, value);
        }
    }

    handleBooleanSelection = event => {
        const value = event.target.value;
        let boolValue = false;

        if (value === "true") {
            boolValue = true;
        }

        this.setState({
            selectedValue: boolValue
        });
        if (this.props.onChangeSelection) {
            this.props.onChangeSelection(this.props.parameter.externalid, boolValue);
        }
    }

    render() {
        const {parameter, possibleSelections, special} = this.props;
        const {selectedValue} = this.state;

        const SelectName = this.components[parameter['RuleEngine:attr_ui_class']];

        return (
            <div className={styles.parameter}>
                <h3 className={styles.parameterName}>
                    <SnippetContent id={parameter.externalid + '_name'} fallback={parameter.name} />
                    <SnippetInfoIcon id={parameter.externalid + '_info'} />
                </h3>
                <div>
                    {special === 'bhs' && parameter.parameterType === 'INTEGER' && possibleSelections && (
                        <div className={styles.parameterValues}>
                            <TextSelect name={parameter.name} selections={possibleSelections} selectedValue={selectedValue} onChange={this.handleSelection}  />
                        </div>
                    )}
                </div>
                <div>
                    {parameter.parameterType === 'VALUESET' && parameter.possibleSelections && (
                        <div className={styles.parameterValues}>
                            <SelectName name={parameter.name} selections={parameter.possibleSelections} selectedValue={selectedValue} onChange={this.handleSelection}  />
                        </div>
                    )}
                </div>
                <div>
                    {parameter.parameterType === 'BOOLEAN' && (
                        <ToggleSelect name={parameter.name} selectedValue={selectedValue} onChange={this.handleBooleanSelection} />
                    )}
                </div>
            </div>
        );
    }
}

export default Parameter;
