import * as ConfigurationService from '../services/ConfigurationService'
import * as ActionTypes from './index';

export const BOOT_REQUEST = 'BOOT_REQUEST';
export const BOOT_SUCCESS = 'BOOT_SUCCESS';
export const BOOT_FAILURE = 'BOOT_FAILURE';

export function bootRequest() {
    return {
        type: BOOT_REQUEST
    }
}

export function bootSuccess(payload) {
    return {
        type: BOOT_SUCCESS,
        payload: payload
    }
}

export function bootFailure(payload) {
    return {
        type: BOOT_FAILURE,
        payload: payload
    }
}

export function boot() {
    return (dispatch) => {
        dispatch(bootRequest());

        ConfigurationService.boot()
            .then(response => {
                dispatch(bootSuccess(response));
                dispatch(ActionTypes.fetchSnippets());
            })
            .catch(error => {
                dispatch(bootFailure(error));
            });
    }
}
