import React, { Component } from 'react';
import {Link} from "@reach/router";

import {connect} from "react-redux";

import AppInset from "../components/AppInset";

import SnippetContent from './Partials/SnippetContent';

const mapStateToProps = (state) => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

class Home extends Component {

    render() {
        return (
            <AppInset>
                    <h1>
                        <strong>
                            <SnippetContent id="p_label_configurators" fallback="Konfiguratoren" />
                        </strong>
                    </h1>
                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <Link className="card bg-dark text-white border-0" to="reno_elements">
                                <img alt="" className="card-img img-fluid" src={require('../assets/configurator-reno_elements.png')} />
                                <div className="card-img-overlay">
                                    <h4>Reno Elements</h4>
                                </div>
                            </Link>
                        </div>

                        {
                            <div className="col-sm-6 col-md-4">
                                <Link className="card bg-dark text-white border-0" to="city_elements">
                                    <img alt="" className="card-img img-fluid" src={require('../assets/configurator-city_elements.png')} />
                                    <div className="card-img-overlay">
                                        <h4>City Elements</h4>
                                    </div>
                                </Link>
                            </div>
                        }
                    </div>
            </AppInset>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
