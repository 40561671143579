import * as ConfigurationService from '../services/ConfigurationService'

export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';

export function fetchProductRequest() {
    return {
        type: FETCH_PRODUCT_REQUEST
    }
}

export function fetchProductSuccess(payload) {
    return {
        type: FETCH_PRODUCT_SUCCESS,
        payload: payload
    }
}

export function fetchProductFailure(payload) {
    return {
        type: FETCH_PRODUCT_FAILURE,
        payload: payload
    }
}

export function fetchProduct(params) {
    return (dispatch, getState) => {
        dispatch(fetchProductRequest());

        const state = getState();
        const locale = state.locale;

        ConfigurationService.fetchProduct(params, locale)
            .then(response => {
                dispatch(fetchProductSuccess(response));
            })
            .catch(error => {
                dispatch(fetchProductFailure(error));
            });
    }
}
