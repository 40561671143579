import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'

import './index.css';
import App from './containers/App';
import {store, persistor} from './store';

render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);
