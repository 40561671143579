export const RESET = 'RESET';

export function resetAction() {
    return {
        type: RESET
    }
}

export function reset() {
    return (dispatch) => {
        dispatch(resetAction());
    }
}
