import * as ActionTypes from '../actions'

const initialState = {
    locale: 'de',
    configuratorId: false,
    parameterSet: false,
    faceting: {},
    facts: {},
    products: {},
    accessories: {},
    snippets: {},
    isLoading: false,
    isBooting: true,
    hasErrors: false
};

const Configuration = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.BOOT_REQUEST:
            return { ...state, isBooting: true};
        case ActionTypes.BOOT_SUCCESS:
            return { ...state, isBooting: false};
        case ActionTypes.BOOT_FAILURE:
            return { ...state, isBooting: true, hasErrors: true};

        case ActionTypes.FETCH_SNIPPETS_REQUEST:
            return { ...state, isLoading: true};
        case ActionTypes.FETCH_SNIPPETS_SUCCESS:
            const snippets = { ...state.snippets}
            action.payload.data.forEach((value) => {
                const externalid = value.externalids[0].externalId;
                snippets[externalid] = value;
            });
            return { ...state, snippets: snippets, isLoading: false};
        case ActionTypes.FETCH_SNIPPETS_FAILURE:
            return { ...state, isLoading: false};

        case ActionTypes.FETCH_PARAMETERSET_REQUEST:
            return { ...state, isLoading: true};
        case ActionTypes.FETCH_PARAMETERSET_SUCCESS:
            return { ...state, parameterSet: action.payload, isLoading: false};
        case ActionTypes.FETCH_PARAMETERSET_FAILURE:
            return { ...state, isLoading: false};

        case ActionTypes.FETCH_FACETS_REQUEST:
            return { ...state, isLoading: true};
        case ActionTypes.FETCH_FACETS_SUCCESS: {
            const id = action.payload.id;
            const faceting = {...state.faceting};
            faceting[id] = action.payload.data;
            return { ...state, faceting: faceting, isLoading: false};
        }
        case ActionTypes.FETCH_FACETS_FAILURE:
            return { ...state, isLoading: true};

        case ActionTypes.FETCH_PRODUCT_SUCCESS: {
            const id = action.payload.id;
            const products = {...state.products};
            products[id] = action.payload.data;
            return { ...state, products: products, isLoading: false};
        }

        case ActionTypes.FETCH_ACCESSORIES_SUCCESS: {
            const id = action.payload.id;
            const accessories = {...state.accessories};
            accessories[id] = action.payload.data.data;
            return { ...state, accessories: accessories, isLoading: false};
        }

        case ActionTypes.EXECUTE_FACTS_REQUEST:
            return { ...state, isLoading: true};
        case ActionTypes.EXECUTE_FACTS_SUCCESS: {
            const facts = action.payload;
            const categories = facts.bom?.categories;
            if (categories) {
                categories.sort((a, b) => (a.id > b.id) ? 1 : -1);
                /*
                const remappedCategories = categories.map(object => {
                    return {id: object.id.substring(3), entries: object.entries};
                });
                */
                facts.bom.categories = categories;
            }
            delete(facts['p_revalidate']);

            return { ...state, facts: facts, isLoading: false};
        }
        case ActionTypes.EXECUTE_FACTS_FAILURE:
            return { ...state, isLoading: false};

        case ActionTypes.RESET:
            return { ...state, facts: {}, isBooting: false, products: {}, faceting: {}, accessories: {}};

        case ActionTypes.SWITCH_CONFIGURATOR:
            return { ...state, configuratorId: action.payload};

        case ActionTypes.SWITCH_LOCALE:
            return { ...state, locale: action.payload};

        case ActionTypes.SWITCH_RESUME_PATHS:
            return { ...state, resumePaths: action.payload};

        default:
            return state;
    }
}

export default Configuration;
