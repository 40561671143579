import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Link} from "@reach/router";

import styles from './SettingsView.module.css';

import AppInset from "../../components/AppInset";

import * as ActionTypes from '../../actions';

import Parameter from '../../components/Parameter';

import SnippetContent from '../Partials/SnippetContent';

const mapStateToProps = (state) => {
    return {
        facts: state.facts,
        parameterSet: state.parameterSet,
        resumePaths: state.resumePaths
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        switchResumePaths: (paths) => dispatch(ActionTypes.switchResumePaths(paths)),
        executeFacts: (ruleSet, facts) => dispatch(ActionTypes.executeFacts(ruleSet, facts))
    };
};

class SettingsView extends Component {

    constructor(props) {
        super(props);
        this.inputRefs = [];
    }

    componentDidMount() {
        const {view} = this.props;

        const resumePaths = {settings: view.path, configuration: view.activities[0].requirements[0].path};
        this.props.switchResumePaths(resumePaths);
    }

    handleChangeFact = (parameter, value) => {
        const {view, parameterSet} = this.props;

        let parameters = Object.fromEntries(
            Object.entries(this.inputRefs).map(([key, value]) => [key, value.state.selectedValue])
        );

        parameters[parameter] = value;

        if (parameterSet.ruleSet) {
            const facts = Object.assign({}, parameters, {p_valid: false, p_changed: parameter, p_produkt_auswahl: view.externalid});

            this.props.executeFacts(parameterSet.ruleSet, facts);
        }
    }

    handleExecuteFacts = event => {
        const {view, parameterSet} = this.props;

        const parameters = Object.fromEntries(
            Object.entries(this.inputRefs).map(([key, value]) => [key, value.state.selectedValue])
        );

        // Don't merge with facts from state for now ...
        // const facts = Object.assign({}, this.props.facts, parameters, {p_valid: false});

        if (parameterSet.ruleSet) {
            const facts = Object.assign({}, parameters, {p_valid: false, p_changed: "CONFIG_START", p_produkt_auswahl: view.externalid, p_validate_modals: true});
            this.props.executeFacts(parameterSet.ruleSet, facts);
        }

        this.props.navigate('../' + view.activities[0].requirements[0].path);
    }

    getConfiguratorHeadline() {
        const {resumePaths} = this.props;

        if (resumePaths) {
            switch (resumePaths.settings) {
                case 'ce180_grundeinstellungen':
                    return 'p_ce_headline_180';
                case 'ce200_grundeinstellungen':
                    return 'p_ce_headline_200';
                case 'ce230_grundeinstellungen':
                    return 'p_ce_headline_230';
                case 're_grundeinstellungen':
                    return 'Reno Elements'
                default:
                    return '';
            }
        }

        return '';
    }

    render() {
        const {view, facts} = this.props;
        const {bom} = facts;

        return (
            <AppInset>
                <Link to={'/'} className={styles.backLink}>
                    <i className="fa far fa-angle-left"></i> <SnippetContent id="p_label_all_configurators" fallback="Alle Konfiguratoren" />
                </Link>
                <h1 className={styles.heading}>
                    <SnippetContent id={this.getConfiguratorHeadline()} fallback={this.getConfiguratorHeadline()} />
                </h1>
                <div className={styles.settingsView}>
                    <h2>
                        <SnippetContent id={'p_' + view.externalid} fallback={view.name} />
                    </h2>

                    {bom && facts['p_valid'] && (
                        <div className="alert alert-info">
                            <SnippetContent id="p_alert_reset" fallback="Achtung, Ihre aktuelle Konfiguration wird bei Änderung der Grundeinstellungen zurückgesetzt." />
                        </div>
                    )}

                    {view.parameters && (
                        <div>
                            {view.parameters.map((value) => (
                                <Parameter ref={node => this.inputRefs[value.externalid] = node} onChangeSelection={this.handleChangeFact} selectedValue={facts[value.externalid]} parameter={value} key={value.id} />
                            ))}
                        </div>
                    )}

                    <button className="btn btn-primary" type="button" onClick={this.handleExecuteFacts}>
                        <SnippetContent id="p_button_apply" fallback="Auswahl übernehmen" />
                    </button>

                    {bom && facts['p_valid'] && (
                        <Link className="btn btn-outline-primary" to={"../" + view.activities[0].requirements[0].path}>
                            <SnippetContent id="p_button_cancel" fallback="Abbrechen" />
                        </Link>
                    )}
                </div>
            </AppInset>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsView);
