import * as ConfigurationService from '../services/ConfigurationService'

export const FETCH_FACETS_REQUEST = 'FETCH_FACETS_REQUEST';
export const FETCH_FACETS_SUCCESS = 'FETCH_FACETS_SUCCESS';
export const FETCH_FACETS_FAILURE = 'FETCH_FACETS_FAILURE';

export function fetchFacetsRequest() {
    return {
        type: FETCH_FACETS_REQUEST
    }
}

export function fetchFacetsSuccess(payload) {
    return {
        type: FETCH_FACETS_SUCCESS,
        payload: payload
    }
}

export function fetchFacetsFailure(payload) {
    return {
        type: FETCH_FACETS_FAILURE,
        payload: payload
    }
}

export function fetchFacets(id, filter, faceting) {
    return (dispatch, getState) => {
        dispatch(fetchFacetsRequest());

        const state = getState();
        const locale = state.locale;

        ConfigurationService.fetchFacets(id, filter, faceting, locale)
            .then(response => {
                dispatch(fetchFacetsSuccess(response));
            })
            .catch(error => {
                dispatch(fetchFacetsFailure(error));
            });
    }
}
