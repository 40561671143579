import React, { Component } from 'react';
import onClickOutside from "react-onclickoutside";

import styles from './AddButton.module.css';

class AddButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };
    }

    handleToggle = () => {
        const {open} = this.state;
        this.setState({
            open: ! open
        });
    }

    handleClickOutside = () => {
        this.setState({
            open: false
        });
    }

    render() {
        const {children} = this.props;
        const {open} = this.state;

        return (
            <div className={styles.addButton}>
                <button className={styles.addButtonToggle} onClick={this.handleToggle}>
                    {open ? (
                        <i className="fa far fa-minus"></i>
                        ) : (
                        <i className="fa far fa-plus"></i>
                    )}
                </button>
                {open && (
                    <div className={styles.addButtonDropdown} onClick={this.handleToggle}>
                        {children}
                    </div>
                )}
            </div>
        );
    }
}

export default onClickOutside(AddButton);
