import React, { Component } from 'react';
import {connect} from "react-redux";

const mapStateToProps = (state) => {
    return {
        snippets: state.snippets
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

class SnippetLink extends Component {

    render() {
        const {id, fallback, className, snippets, children} = this.props;
        const snippet = snippets[id] ? snippets[id].content : fallback;

        return (
            <a className={className} href={snippet}>
                {children}
            </a>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnippetLink);
