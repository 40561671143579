import React, { Component } from 'react';
import mergeClassNames from 'merge-class-names'

import styles from './ToggleSelect.module.css';

class ToggleSelect extends Component {

    render() {
        const {name, selectedValue, onChange} = this.props;

        return (
            <div className={mergeClassNames(styles.toggleSelect, selectedValue === true ? styles.toggleSelectTrue : styles.toggleSelectFalse)}>
                <div className={styles.toggleSwitch} />
                <div className={mergeClassNames(styles.item, styles.itemFalse)} key="false">
                    <input checked={selectedValue === false} onChange={onChange} value={false} type="radio" name={encodeURI(name)} id={"checkbox-" + encodeURI(name) + "-false"}/>
                    <label htmlFor={"checkbox-" + encodeURI(name) + "-false"}>
                        Nein
                    </label>
                </div>
                <div className={mergeClassNames(styles.item, styles.itemTrue)} key="true">
                    <input checked={selectedValue === true} onChange={onChange} value={true} type="radio" name={encodeURI(name)} id={"checkbox-" + encodeURI(name) + "-true"}/>
                    <label htmlFor={"checkbox-" + encodeURI(name) + "-true"}>
                        Ja
                    </label>
                </div>
            </div>
        );
    }
}

export default ToggleSelect;
