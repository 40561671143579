import axios from 'axios'
import tokenProvider from 'axios-token-interceptor'

import handleError from './handleError'

/*
export const API_ENDPOINT = 'https://hess-dev.xom.one/xom-rest/'
export const API_OAUTH_ENDPOINT = 'https://hess-dev.xom.one/xom-daemon/oauth/token?grant_type=client_credentials'
export const API_CLIENT_ID = 'XOMRest'
export const API_CLIENT_SECRET = '27a3ebf8-2e81-4917-aaca-208c17deaa5e'
*/

export const API_ENDPOINT = 'https://hess1.xom.one/xom-rest/'
export const API_OAUTH_ENDPOINT = 'https://hess1.xom.one/xom-daemon/oauth/token?grant_type=client_credentials'
export const API_CLIENT_ID = 'hesspk'
export const API_CLIENT_SECRET = 'Cm141he9QTjje2h0REbQ'

const xom = axios.create({
    baseURL: API_ENDPOINT
});
xom.interceptors.request.use(tokenProvider({
    getToken: () => localStorage.getItem('xom_access_token')
}));

export const boot = () => {
    return new Promise((resolve, reject) => {
        axios.post(API_OAUTH_ENDPOINT,
            {},
            { headers:
                {'Content-Type': 'application/x-www-form-urlencoded'},
                auth: {username: API_CLIENT_ID, password: API_CLIENT_SECRET}
            }
        )
        .then(response => {
            const access_token = response.data.access_token;
            localStorage.setItem('xom_access_token', access_token);

            resolve({});
        })
        .catch(error => { reject(handleError(error)) });
    });
};

export const fetchSnippets = (locale) => {
    return new Promise((resolve, reject) => {
        const params = {
            search: 'path =contains= hesspktb:root_mv_text_blocks',
            locale: locale
        };

        xom.get('snippets', {params: params})
            .then(response => { resolve(response.data) })
            .catch(error => { reject(handleError(error)) });
    });
}

export const fetchParameterSet = (identifier, locale) => {
    return new Promise((resolve, reject) => {
        const params = {
            locale: locale
        };

        xom.get('parametersets/' + identifier + '/hierarchy', {params: params})
            .then(response => { resolve(response.data) })
            .catch(error => { reject(handleError(error)) });
    });
};

export const fetchFacets = (id, filter, faceting, locale) => {
    return new Promise((resolve, reject) => {
        const params = {
            faceting: faceting,
            search: filter,
            top: 9999,
            locale: locale
        };

        xom.get('products', {params: params})
            .then(response => { resolve({id: id, data: response.data}) })
            .catch(error => { reject(handleError(error)) });
    })
};

export const executeFacts = (ruleSet, facts) => {
    return new Promise((resolve, reject) => {
        xom.post('rulesets/' + ruleSet + '/facts', {}, {data: facts})
            .then(response => { resolve(response.data) })
            .catch(error => { reject(handleError(error)) });
    })
};

export const fetchProduct = (id, locale) => {
    return new Promise((resolve, reject) => {
        const params = {
            locale: locale,
            format: 'object',
            expand: 'values',
            showvalue: 'pk_bild_block,pk_bild,pk_farbe,pk_farbe_hinweis,pk_produkt_bild,pk_bild_erdstueck,pk_matktxt,pk_matnr,pk_variantenbezeichnung,pk_datalist,pk_zubehoer,pk_anzahl_zubehoer,pk_erdstueck_block,pk_erdstueck_bild,pk_erdstueck_pos,pk_erdstueck_laenge,pk_hoehe,pk_leistung,pk_hinweis_ausland_crve'
        };

        xom.get('products/' + id, {params: params})
            .then(response => { resolve({id: id, data: response.data}) })
            .catch(error => { reject(handleError(error)) });
    })
};

export const fetchAccessories = (id, accessoryProductGroupId, locale) => {
    return new Promise((resolve, reject) => {
        const params = {
            locale: locale,
            format: 'object',
            expand: 'values',
            showvalue: 'pk_produkt_bild,pk_matktxt,pk_matnr,pk_variantenbezeichnung,pk_hinweis_ausland_crve,pk_datalist'
        };

        xom.get('productgroups/' + id + '/products/', {params: params})
            .then(response => { resolve({id: id, data: response.data}) })
            .catch(error => { reject(handleError(error)) });
    })
};
