import * as ConfigurationService from '../services/ConfigurationService'

export const EXECUTE_FACTS_REQUEST = 'EXECUTE_FACTS_REQUEST';
export const EXECUTE_FACTS_SUCCESS = 'EXECUTE_FACTS_SUCCESS';
export const EXECUTE_FACTS_FAILURE = 'EXECUTE_FACTS_FAILURE';

export function executeFactsRequest() {
    return {
        type: EXECUTE_FACTS_REQUEST
    }
}

export function executeFactsSuccess(payload) {
    return {
        type: EXECUTE_FACTS_SUCCESS,
        payload: payload
    }
}

export function executeFactsFailure(payload) {
    return {
        type: EXECUTE_FACTS_FAILURE,
        payload: payload
    }
}

export function executeFacts(ruleSet, facts) {
    return (dispatch) => {
        dispatch(executeFactsRequest());

        ConfigurationService.executeFacts(ruleSet, facts)
            .then(response => {
                dispatch(executeFactsSuccess(response));
            })
            .catch(error => {
                dispatch(executeFactsFailure(error));
            });
    }
}
