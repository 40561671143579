import React, { Component } from 'react';
import {connect} from "react-redux";
import { Redirect, Link, Router} from "@reach/router";

import ModalView from "./ModalView";

import RenoProductCanvas from "../Canvas/RenoProductCanvas";

import styles from "./RenoProductView.module.css";

import * as ActionTypes from "../../actions";

import AppInset from "../../components/AppInset";
import Navigator from "../../components/Navigator";

import SnippetContent from '../Partials/SnippetContent';

const mapStateToProps = (state) => {
    return {
        facts: state.facts,
        isLoading: state.isLoading,
        parameterSet: state.parameterSet,
        resumePaths: state.resumePaths
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        executeFacts: (ruleSet, facts) => dispatch(ActionTypes.executeFacts(ruleSet, facts))
    };
};

class RenoProductView extends Component {

    getModals(activities, modals) {
        if (activities) {
            activities.map((activity) => {
                if (activity.activitytype === 'open_modal_A' || activity.activitytype === 'open_modal_F/B' || activity.activitytype === 'open_modal_ACC') {
                    activity.requirements.map((view) => {
                        view.path = view.externalid;
                        modals.push(view);
                        return view;
                    });
                }
                return activity;
            });
        }

        return modals;
    }

    hasElementsOfCategory(category) {
        const {facts} = this.props;
        const {bom} = facts;

        let hasElements = false;

        if (bom && bom.categories) {
            bom.categories.forEach((value) => {
                if (value.id === category) {
                    if (value.entries && value.entries[0] && value.entries[0].id && value.entries[0].meta.inUse) {
                        hasElements = true
                    }
                }
            });
        }

        return hasElements;
    }

    getIsValidConfiguration() {
        if (this.hasElementsOfCategory('02-A')) {
            return true;
        }

        return false;
    }

    handleShowResults = () => {
        const {parameterSet} = this.props;
        let facts = Object.assign({}, this.props.facts, {p_revalidate: true});

        delete(facts['p_valid']);

        const mergedFacts = Object.assign(facts, {p_revalidate: true, p_valid: true});
        this.props.executeFacts(parameterSet.ruleSet, mergedFacts);
    }

    render() {
        const {view, facts, isLoading, navigate, parameterSet, resumePaths} = this.props;
        const {bom} = facts;
        const modals = this.getModals(view.activities, []);
        const isValidConfiguration = this.getIsValidConfiguration();

        return (
            <AppInset>
                <div className={styles.configuratorHeader}>
                    <Link to={'/'} className={styles.backLink}>
                        <i className="fa far fa-angle-left"></i> <SnippetContent id="p_label_all_configurators" fallback="Alle Konfiguratoren" />
                    </Link>
                    <h1 className={styles.heading}>{parameterSet.name}</h1>
                </div>

                <Link to={'../' + resumePaths.settings} className={styles.settingsButton}>
                    <i className="fa far fa-cogs"></i>
                </Link>

                { ! isLoading && ! facts['bom'] && (
                    <Redirect noThrow to="../" />
                )}

                {modals && (
                    <Router primary={false}>
                        {modals.map((value) => (
                            <ModalView key={value.id} view={value} path={value.path + '/:position/:entryPosition/:orientation'} navigate={navigate} />
                        ))}
                    </Router>
                )}

                {bom && (
                    <div className={styles.canvasWrap} style={{height: (facts['p_profilhoehe'] / 3 + 300) + 'px'}}>
                        <RenoProductCanvas view={view} orientation={'front'} showOrientation={true} enableButtons={true} hideButtons={false} disableRefetch={false} />
                        <RenoProductCanvas view={view} orientation={'back'} showOrientation={true} enableButtons={true} hideButtons={false} disableRefetch={false} />
                    </div>
                )}

                {bom && (
                    <Navigator>
                        <div className={styles.canvasMiniature} style={{height: (facts['p_profilhoehe'] / 3 + 300) + 'px'}}>
                            <RenoProductCanvas view={view} orientation={'front'} enableButtons={false} hideButtons={false} disableRefetch={true} />
                            <RenoProductCanvas view={view} orientation={'back'} enableButtons={false} hideButtons={false} disableRefetch={true} />
                        </div>
                    </Navigator>
                )}

                {view.activities && (
                    <div className={styles.resultsButton}>
                        {view.activities.map((value) => (
                            <div key={value.name} className="text-right">
                                {value.activitytype === 'navigation' && (
                                    <>
                                        {isValidConfiguration ? (
                                            <Link className="btn btn-primary" onClick={this.handleShowResults} to={'../' + value.requirements[0].path}>
                                                <SnippetContent id="p_button_result" fallback="Ergebnis anzeigen" />
                                            </Link>
                                        ) : (
                                            <div className="alert alert-danger mb-1 py-0_25 px-0_5">
                                                <small><SnippetContent id="p_re_label_top_alert" fallback="" /></small>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </AppInset>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RenoProductView);
