export const SWITCH_RESUME_PATHS = 'SWITCH_RESUME_PATHS';

export function switchResumePathsAction(payload) {
    return {
        type: SWITCH_RESUME_PATHS,
        payload: payload
    }
}

export function switchResumePaths(paths) {
    return (dispatch) => {
        dispatch(switchResumePathsAction(paths));
    }
}
