import React, { Component } from 'react';

import styles from './ButtonPlaceholder.module.css';

class ButtonPlaceholder extends Component {

    render() {
        return (
            <div className={styles.buttonPlaceholderWrap}>
                <div className={styles.buttonPlaceholder}></div>
            </div>
        );
    }
}

export default ButtonPlaceholder;
