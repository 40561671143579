import * as ConfigurationService from '../services/ConfigurationService'

export const FETCH_SNIPPETS_REQUEST = 'FETCH_SNIPPETS_REQUEST';
export const FETCH_SNIPPETS_SUCCESS = 'FETCH_SNIPPETS_SUCCESS';
export const FETCH_SNIPPETS_FAILURE = 'FETCH_SNIPPETS_FAILURE';

export function fetchSnippetsRequest() {
    return {
        type: FETCH_SNIPPETS_REQUEST
    }
}

export function fetchSnippetsSuccess(payload) {
    return {
        type: FETCH_SNIPPETS_SUCCESS,
        payload: payload
    }
}

export function fetchSnippetsFailure(payload) {
    return {
        type: FETCH_SNIPPETS_FAILURE,
        payload: payload
    }
}

export function fetchSnippets() {
    return (dispatch, getState) => {
        dispatch(fetchSnippetsRequest());

        const state = getState();
        const locale = state.locale;

        ConfigurationService.fetchSnippets(locale)
            .then(response => {
                dispatch(fetchSnippetsSuccess(response));
            })
            .catch(error => {
                dispatch(fetchSnippetsFailure(error));
            });
    }
}
