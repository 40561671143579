import React, { Component } from 'react';
import {connect} from "react-redux";
import { Redirect, Link, Router} from "@reach/router";

import ModalView from "./ModalView";

import CityProductCanvas from "../Canvas/CityProductCanvas";

import styles from "./CityProductView.module.css";

import SnippetContent from '../Partials/SnippetContent';

import * as ActionTypes from "../../actions";

import AppInset from "../../components/AppInset";
import Navigator from "../../components/Navigator";

const mapStateToProps = (state) => {
    return {
        facts: state.facts,
        isLoading: state.isLoading,
        parameterSet: state.parameterSet,
        resumePaths: state.resumePaths
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        executeFacts: (ruleSet, facts) => dispatch(ActionTypes.executeFacts(ruleSet, facts))
    };
};

class CityProductView extends Component {

    hasElementsOfCategory(category) {
        const {facts} = this.props;
        const {bom} = facts;

        let hasElements = false;

        if (bom && bom.categories) {
            bom.categories.forEach((value) => {
                if (value.id === category) {
                    if (value.entries && value.entries[0]) {
                        hasElements = true
                    }
                }
            });
        }

        return hasElements;
    }

    getIsValidConfiguration() {
        if (this.hasElementsOfCategory('01-G') && this.hasElementsOfCategory('02-A')) {
            return true;
        }

        return false;
    }

    handleShowResults = () => {
        const {parameterSet} = this.props;
        let facts = Object.assign({}, this.props.facts, {p_revalidate: true});

        delete(facts['p_valid']);

        const mergedFacts = Object.assign(facts, {p_revalidate: true, p_valid: true});
        this.props.executeFacts(parameterSet.ruleSet, mergedFacts);
    }

    isValidModal(modal) {
        const {facts} = this.props;
        const validModals = facts.p_valid_modals;

        if (validModals && validModals[modal] && validModals[modal] > 0) {
            return true;
        }

        return false;
    }

    hasValidModals(requirements) {
        let i = 0;

        requirements.forEach((value) => {
            if (this.isValidModal(value.externalid)) {
                i++;
            }
        });

        if (i > 0) {
            return true;
        }

        return false;
    }

    getModals(activities, modals) {
        if (activities) {
            activities.map((activity) => {
                activity.requirements.map((view) => {
                    view.path = view.externalid;
                    modals.push(view);

                    if (view.activities) {
                        view.activities.map((a) => {
                            a.requirements.map((v) => {
                                v.path = v.externalid;
                                v.is_sub_modal = true;
                                modals.push(v);

                                return v;
                            });

                            return a;
                        });
                    }

                    return view;
                });
                return activity;
            });
        }

        return modals;
    }

    getConfiguratorHeadline() {
        const {resumePaths} = this.props;

        if (resumePaths) {
            switch (resumePaths.settings) {
                case 'ce180_grundeinstellungen':
                    return 'p_ce_headline_180';
                case 'ce200_grundeinstellungen':
                    return 'p_ce_headline_200';
                case 'ce230_grundeinstellungen':
                    return 'p_ce_headline_230';
                default:
                    return '';
            }
        }

        return '';
    }

    getPixelOffset() {
        const {resumePaths} = this.props;

        if (resumePaths) {
            switch (resumePaths.settings) {
                case 'ce180_grundeinstellungen':
                    return 300;
                case 'ce200_grundeinstellungen':
                    return 440;
                case 'ce230_grundeinstellungen':
                    return 440;
                default:
                    return 0;
            }
        }

        return 0;
    }

    render() {
        const {view, facts, isLoading, navigate, resumePaths} = this.props;
        const {bom} = facts;
        const modals = this.getModals(view.activities, []);
        const isValidConfiguration = this.getIsValidConfiguration();

        return (
            <AppInset>
                <div className={styles.configuratorHeader}>
                    <Link to={'/'} className={styles.backLink}>
                        <i className="fa far fa-angle-left"></i> <SnippetContent id="p_label_all_configurators" fallback="Alle Konfiguratoren" />
                    </Link>
                    <h1 className={styles.heading}>
                        <SnippetContent id={this.getConfiguratorHeadline()} fallback="" />
                    </h1>
                </div>

                <Link to={'../' + resumePaths.settings} className={styles.settingsButton}>
                    <i className="fa far fa-cogs"></i>
                </Link>

                <div className={styles.procedureInfo}>
                   <SnippetContent id="p_ce_procedure_info" />

                   {view.externalid === 'ce200_produktansicht' && (
                        <div className={styles.procedureInfoVideo}>
                            <a href="https://www.youtube.com/watch?v=15409dM1-Io" target="_blank" rel="noopener noreferrer">
                                <img alt="" className="img-fluid" src={require('../../assets/ce200-video.png')} />
                            </a>
                        </div>
                    )}
                </div>


                { ! isLoading && ! facts['bom'] && (
                    <Redirect noThrow to="../" />
                )}

                {modals && (
                    <Router primary={false}>
                        {modals.map((value) => (
                            <ModalView key={value.id} view={value} path={value.externalid + '/:position/:entryPosition'} navigate={navigate} />
                        ))}
                    </Router>
                )}

                {bom && (
                    <div className={styles.canvasWrap} style={{height: (facts['p_profilhoehe'] / 3 + this.getPixelOffset()) + 'px'}}>
                        <CityProductCanvas view={view} showOrientation={true} enableButtons={true} hideButtons={false} disableRefetch={false} />
                    </div>
                )}

                {bom && (
                    <>
                        <Navigator>
                            <div className={styles.canvasMiniature} style={{height: (facts['p_profilhoehe'] / 3 + this.getPixelOffset()) + 'px'}}>
                                <CityProductCanvas view={view} enableButtons={false} hideButtons={false} disableRefetch={true} />
                            </div>
                        </Navigator>
                        <div className={styles.canvasHeight}>
                            {facts['p_profilhoehe']}&nbsp;mm
                        </div>
                    </>
                )}

                {view.activities && (
                    <div className={styles.resultsButton}>
                        {view.activities.map((value) => (
                            <>
                                {value.activitytype === 'ce_open_modal_ZE' && (
                                    <>
                                        {this.hasValidModals(value.requirements) ? (
                                            <></>
                                        ) : (
                                            <div className="alert alert-info d-inline-block mb-1 py-0_25 px-0_5">
                                                <small><SnippetContent id="p_ce_max_height_info" fallback="Für weitere Zwischenelemente muss die Säulenhöhe nach oben angepasst und die CITY ELEMENTS neu geplant werden." /></small>
                                            </div>
                                        )}
                                    </>
                                )}

                                <div key={value.name} className="text-right">
                                    {value.activitytype === 'navigation' && (
                                        <>
                                            {isValidConfiguration ? (
                                                <Link className="btn btn-primary" onClick={this.handleShowResults} to={'../' + value.requirements[0].path}>
                                                    <SnippetContent id="p_button_result" fallback="Ergebnis anzeigen" />
                                                </Link>
                                            ) : (
                                                <div className="alert alert-danger d-inline-block mb-1 py-0_25 px-0_5">
                                                    <small><SnippetContent id="p_ce_label_top_base_alert" fallback="Achtung: Konfiguration nur mit Abschluss- und Grundelement möglich." /></small>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            </>
                        ))}
                    </div>
                )}
            </AppInset>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CityProductView);
