import React, { Component } from 'react';
import {connect} from 'react-redux';
import {Router, navigate} from "@reach/router";

import * as ActionTypes from '../actions';

import View from './Views/View';
import NewView from "./Views/NewView";

const mapStateToProps = (state) => {
    return {
        parameterSet: state.parameterSet
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchParameterSet: (identifier) => dispatch(ActionTypes.fetchParameterSet(identifier))
    };
};

class Configurator extends Component {

    componentDidMount() {
        this.props.fetchParameterSet('RuleEngine:' + this.props.configuratorId);
    }

    getViews(parameterSet, views) {
        if (parameterSet?.parametergroups) {
            parameterSet.parametergroups.map((parametergroup) => {
                const view = parametergroup;
                view.path = view.externalid;
                views.push(view);

                if (view.activities) {
                    view.activities.map((value) => {
                        this.getViewsRecursively(value, views);
                        return value;
                    });
                }
                return parametergroup;
            });

        }
        return views;
    }

    getViewsRecursively(activity, views) {
        if (activity.activitytype === 'navigation') {
            const view = activity.requirements[0];
            view.path = view.externalid;
            views.push(view);

            if (view.activities) {
                view.activities.map((value) => {
                    this.getViewsRecursively(value, views);
                    return value;
                });
            }
        }
        return views;
    }

    render() {
        const {parameterSet} = this.props;
        const views = this.getViews(parameterSet, []);

        return parameterSet && (
            <div>
                {views && (
                    <Router>
                        <NewView path={'/'} views={views} navigate={navigate} />
                        {views.map((value) => (
                            <View key={value.id} views={views} view={value} path={value.path + '/*'} navigate={navigate} />
                        ))}
                    </Router>
                )}
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Configurator);
