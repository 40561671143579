import axios from 'axios'
import handleError from './handleError'
import qs from 'qs'

export const API_ENDPOINT = 'https://www.hess.eu/index.php'

const service = axios.create({
    baseURL: API_ENDPOINT,
    paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'brackets'})
    },
});

export const inquireConfiguration = (name, email, company, postalCode, locality, message, facts, configuratorId) => {
    return new Promise((resolve, reject) => {
        const params = {
            id: 1,
            type: 1605085631,
            tx_xomproducts_products: {
                name: name,
                email: email,
                company: company,
                postalCode: postalCode,
                locality: locality,
                message: message,
                facts: JSON.stringify(facts),
                configuratorId: configuratorId
            }
        };

        service.get('', {params: params})
            .then(response => { resolve(response.data) })
            .catch(error => { reject(handleError(error)) });
    });
};
