import React, { Component } from 'react';
import {connect} from "react-redux";

import styles from './SnippetInfoIcon.module.css';

const mapStateToProps = (state) => {
    return {
        snippets: state.snippets
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

class SnippetInfoIcon extends Component {

    htmlContent(html) {
        return {__html: html};
    }

    render() {
        const {id, snippets} = this.props;
        const snippet = snippets[id] ? snippets[id].content : false;

        if (snippet) {
            return (
                <span className={styles.info}>
                    <i className="fa far fa-info-circle" />
                    <div className={styles.infoText} dangerouslySetInnerHTML={this.htmlContent(snippet)}></div>
                </span>
            )
        }

        return false;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnippetInfoIcon);
