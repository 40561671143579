import React, { Component } from 'react';
import {connect} from "react-redux";
import mergeClassNames from 'merge-class-names'

import * as ActionTypes from "../../actions";

import Value from "../../components/Value";

import styles from './AccessoryGroup.module.css';

const mapStateToProps = (state) => {
    return {
        products: state.products,
        accessories: state.accessories
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchAccessories: (id) => dispatch(ActionTypes.fetchAccessories(id))
    };
};

class AccessoryGroup extends Component {

    componentDidMount() {
        const {id} = this.props;
        this.props.fetchAccessories(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {id} = this.props;

        if (prevProps.id && prevProps.id !== id) { // ID has changed ...
            this.props.fetchAccessories(id);
        }
    }

    showvalueReducer = showvalue => (accumulator, currentValue) => {
        if (currentValue.showvalues.includes(showvalue)) {
            accumulator = currentValue;
        }
        return accumulator;
    };

    showvalueMultipleReducer = showvalue => (accumulator, currentValue) => {
        if (currentValue.showvalues.includes(showvalue)) {
            if( ! accumulator) {
                accumulator = [];
            }
            accumulator.push(currentValue);
        }
        return accumulator;
    }

    getValue(node, showvalue) {
        return node?.values?.reduce(this.showvalueReducer(showvalue), null);
    }

    getMultipleValues(node, showvalue) {
        return node?.values?.reduce(this.showvalueMultipleReducer(showvalue), null);
    }

    handleAddAccessory = (value) => event => {
        this.props.onAddAccessory(value);
    }

    handleRemoveAccessory = (value) => event => {
        this.props.onRemoveAccessory(value);
    }

    renderItem(product) {
        const name = this.getValue(product, 'pk_matktxt');
        const artNo = this.getValue(product, 'pk_matnr');
        const description = this.getValue(product, 'pk_variantenbezeichnung');

        return (
            <div className={styles.item}>
                <div className={styles.itemHeading}>
                    <Value value={name} />
                </div>
                <div className={styles.itemSubline}>
                    <Value value={description} /><br />
                    <Value value={artNo} />
                </div>
            </div>
        )
    }

    render() {
        const {id, accessories, selectedAccessories} = this.props;
        const accessory = accessories[id] ? accessories[id] : false;

        return accessory && (
            <>
                {accessory.map((value, index) => (
                    <div key={index} className={styles.accessoriesItem}>
                        <div className={styles.accessoriesItemButton}>
                            {selectedAccessories.includes(value.id) ? (
                                <div className={mergeClassNames(styles.button, styles.buttonActive)} onClick={this.handleRemoveAccessory(value.id)}>
                                    <i className="fa far fa-check"></i>
                                </div>
                            ) : (
                                <div className={styles.button} onClick={this.handleAddAccessory(value.id)}>
                                    <i className="fa far fa-check"></i>
                                </div>
                            )}
                        </div>
                        <div className={styles.accessoriesItemName}>
                            {this.renderItem(value)}
                        </div>
                    </div>
                ))}
            </>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccessoryGroup);
