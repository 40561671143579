import React, { Component } from 'react';
import {connect} from "react-redux";
import onClickOutside from "react-onclickoutside";

import styles from './ModalView.module.css';

import * as ConfigurationService from "../../services/ConfigurationService";
import * as ActionTypes from "../../actions";

import Facet from "../../components/Facet";
import Parameter from "../../components/Parameter";
import LoadingSpinner from "../../components/LoadingSpinner";

import Product from "../Partials/Product";
import ProductName from "../Partials/ProductName";
import SnippetContent from "../Partials/SnippetContent";

const mapStateToProps = (state) => {
    return {
        locale: state.locale,
        faceting: state.faceting,
        facts: state.facts,
        products: state.products,
        isLoading: state.isLoading,
        parameterSet: state.parameterSet,
        configuratorId: state.configuratorId
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchFacets: (id, filter, faceting) => dispatch(ActionTypes.fetchFacets(id, filter, faceting)),
        executeFacts: (ruleSet, facts) => dispatch(ActionTypes.executeFacts(ruleSet, facts))
    };
};

class ModalView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            facets: false,
            products: false,
            isLoading: true,
            filter: false,
            selectedAccessories: []
        };

        this.selectedValues = [];
        this.selectedParameters = [];
    }

    componentDidMount() {
        const {view, position, parameterSet, locale} = this.props;

        this.setState({
            facets: false,
            products: false,
            isLoading: true,
            filter: false,
            selectedAccessories: []
        });

        let facts = Object.assign({}, this.props.facts, {p_modal: view['externalid'], p_position: position});
        delete(facts['p_filter']);

        if (view['RuleEngine:attr_filter'] || view['RuleEngine:attr_faceting']) {

            // Set modal and position first to fetch filter
            //this.props.executeFacts(parameterSet.ruleSet, facts);

            ConfigurationService.executeFacts(parameterSet.ruleSet, facts)
                .then(response => {
                    // Filter with updated p_filter from response
                    let additionalFilter = '';
                    if (response['p_filter']) {
                        additionalFilter = ' and ' + response['p_filter'];

                        this.setState({filter: response['p_filter'], isLoading: false});
                    }

                    ConfigurationService.fetchFacets(view.id, view['RuleEngine:attr_filter'] + additionalFilter, view['RuleEngine:attr_faceting'] + ' minCount 1 orderBy index', locale)
                        .then(response => {
                            let facets = [];

                            if (response.data.meta.faceting) {
                                facets = response.data.meta.faceting?.facets;
                            }
                            const products = response.data.data;

                            this.setState({
                                facets: facets,
                                products: products,
                                isLoading: false,
                                selectedAccessories: []
                            });
                        });
                });
        }
    }

    handleChangeParameter = (parameter, value) => {
        this.selectedParameters[parameter] = value;
    }

    handleSelection = (key, value) => {
        this.selectedValues[key] = value;
        this.performFilter();
    }

    handleDeselection = (key) => {
        delete(this.selectedValues[key]);
        this.performFilter();
    }

    performFilter = () => {
        const {view, locale} = this.props;
        const {filter} = this.state;

        let mergedFilterQueries = null;
        if (filter) {
            mergedFilterQueries = Object.assign({}, this.selectedValues, {prefix: view['RuleEngine:attr_filter'], suffix: filter});
        } else {
            mergedFilterQueries = Object.assign({}, this.selectedValues, {prefix: view['RuleEngine:attr_filter']});
        }
        const filterQueryString = Object.values(mergedFilterQueries).join(' and ');

        ConfigurationService.fetchFacets(view.id, filterQueryString, view['RuleEngine:attr_faceting'] + ' minCount 1 orderBy index', locale)
            .then(response => {
                let facets = [];
                if (response.data.meta.faceting) {
                    facets = response.data.meta.faceting.facets;
                }
                const products = response.data.data;

                this.setState({
                    facets: facets,
                    products: products,
                    selectedAccessories: []
                });
            });

    }

    closeModal() {
        const {parameterSet, position, entryPosition, facts} = this.props;
        const {bom} = facts;

        if (this.isSubModal()) {

            bom.categories = bom.categories.map((value) => {
                if (value.id === position) {
                    value.entries = value.entries.filter(v => parseInt(v.pos) !== parseInt(entryPosition));
                }
                return value;
            });
        }

        // Revalidate all modals now
        const mergedFacts = Object.assign(facts, {bom: bom, p_validate_modals: true});
        this.props.executeFacts(parameterSet.ruleSet, mergedFacts);

        this.setState({...this.state, isLoading: true, facets: false});

        // Close modal by navigating up
        this.props.navigate('../../../');
    }

    handleCloseModal = (event) => {
        event.preventDefault();
        this.closeModal()
    }

    handleClickOutside = () => {
        this.closeModal();
    }

    handleInsertProduct = (product, position, entryPosition, orientation) => event => {
        event.preventDefault();

        const {parameterSet, facts, products, configuratorId} = this.props;
        const {selectedAccessories} = this.state;
        const {bom} = facts;

        bom.categories = bom.categories.map((value) => {
            if (value.id === position) {
                let entries = [];
                if (value.entries) {
                    entries = value.entries;
                }

                // Reno Elements
                if (configuratorId === 'reno_elements') {
                    if (entries[entryPosition]) {
                        entries[entryPosition].id = product;
                        entries[entryPosition].quantity = 1;
                        entries[entryPosition].unit = 'Stück';
                        entries[entryPosition].meta.inUse = true;
                        entries[entryPosition].meta.orientation = orientation;
                    }

                    if (selectedAccessories) {
                        selectedAccessories.forEach((element, index) => {
                            let entry = {id: element, quantity: 1, unit: 'Stück', meta: {inUse: true, orientation: orientation, isAccessory: true}};
                            entries.push(entry);
                        });
                    }
                }
                // City Elements and Arini (for now)
                else {
                    if (this.isSubModal()) {
                        const qty = value.entries.reduce((accumulator, current) => {
                            if (parseInt(current.pos) === parseInt(entryPosition) && current.meta.isAccessory !== true) {
                                accumulator = current.meta.accessoryCount;
                            }
                            return accumulator;
                        }, 1);

                        entries.push({id: product, pos: entryPosition, quantity: qty, unit: 'Stück', meta: {inUse: true, orientation: orientation, isAccessory: true}});
                    } else {
                        const height = this.getValue(products[product], "pk_hoehe");
                        let dynamicHeight = false;

                        let heightValue = 0;
                        if (height) {
                            heightValue = height.value.value;
                        }

                        if (this.selectedParameters && this.selectedParameters['p_bhs_elementhoehe']) {
                            heightValue = facts['p_bhs_filter'][this.selectedParameters['p_bhs_elementhoehe']];
                            dynamicHeight = true;
                        }

                        const accessoryCount = this.getValue(products[product], "pk_anzahl_zubehoer");
                        let accessoryCountValue = 0;
                        if (accessoryCount) {
                            accessoryCountValue = accessoryCount.value.value;
                        }

                        entries.push({id: product, pos: entryPosition, quantity: 1, unit: 'Stück', meta: {inUse: true, accessoryCount: accessoryCountValue, orientation: orientation, height: heightValue, dynamicHeight: dynamicHeight}});

                        if (selectedAccessories) {
                            selectedAccessories.forEach((element, index) => {
                                let entry = {id: element, pos: entryPosition, quantity: 1, unit: 'Stück', meta: {inUse: true, orientation: orientation, isAccessory: true}};
                                entries.push(entry);
                            });
                        }
                    }
                }

                value.entries = entries;
            }
            return value;
        });

        delete(facts['p_valid']);

        const mergedFacts = Object.assign(facts, {bom: bom, p_valid: true, p_validate_modals: true});
        this.props.executeFacts(parameterSet.ruleSet, mergedFacts);

        this.setState({...this.state, isLoading: true, facets: false});

        // Close modal and open sub modals, if there are any
        if (this.hasSubModals()) {
            const subModal = this.getSubModal();

            this.props.navigate('../../../' + subModal + '/' + position + '/' + entryPosition);
        } else {
            // Close modal by navigating up
            this.props.navigate('../../../');
        }
    }

    handleAddAccessory = (value) => {
        const {selectedAccessories} = this.state;
        let newSelectedAccessories = [...selectedAccessories];

        if ( ! selectedAccessories.includes(value)) {
            newSelectedAccessories.push(value);
        }

        this.setState({...this.state, selectedAccessories: newSelectedAccessories});
    }

    handleRemoveAccessory = (value) =>  {
        let {selectedAccessories} = this.state;

        const arrayRemove = (arr, v) => (arr.filter(function(ele){ return ele !== v; }));
        selectedAccessories = arrayRemove(selectedAccessories, value);

        this.setState({...this.state, selectedAccessories: selectedAccessories});
    }

    showvalueReducer = showvalue => (accumulator, currentValue) => {
        if (currentValue.showvalues.includes(showvalue)) {
            accumulator = currentValue;
        }
        return accumulator;
    };

    hasSubModals() {
        const {view} = this.props;

        if (view.activities && view.activities[0]) {
            return true;
        }

        return false;
    }

    getSubModal() {
        const {view} = this.props;

        if (view.activities && view.activities[0]) {
            return view.activities[0].requirements[0].path;
        }

        return false;
    }

    isSubModal() {
        const {view} = this.props;

        if (view.is_sub_modal) { 
            return true;
        }

        return false;
    }

    getValue(node, showvalue) {
        return node?.values?.reduce(this.showvalueReducer(showvalue), null);
    }

    render() {
        const {facts, view, position, entryPosition, orientation} = this.props;
        const {facets, products, isLoading, selectedAccessories} = this.state;

        return (
            <div className={styles.modalWrap}>
                <div className={styles.modal}>
                    <div className={styles.modalHeader}>
                        <h2 class="pr-2">
                            <SnippetContent id={"p_" + view.externalid} fallback={view.name} />
                        </h2>

                        <a href="../../../" className={styles.modalCloseButton} onClick={this.handleCloseModal}>
                            <i className="fa far fa-lg fa-times"></i>
                        </a>
                    </div>

                    {isLoading ? (
                        <div className={styles.modalContent}>
                            <LoadingSpinner />
                        </div>
                    ) : (
                        <>
                            <div className={styles.modalContent}>
                                {view.parameters && (
                                    <div>
                                        {view.parameters.map((value) => (
                                            <>
                                                {value.externalid === 'p_bhs_elementhoehe' && (
                                                   <Parameter onChangeSelection={this.handleChangeParameter} special="bhs" possibleSelections={facts['p_bhs_filter']} selectedValue={this.selectedParameters ? this.selectedParameters[value.externalid] : null} parameter={value} key={value.id} />
                                                )}
                                            </>
                                        ))}
                                    </div>
                                )}
                                {facets && (
                                    <div>
                                        {facets.map((value, i) => (
                                            <>
                                                {(this.selectedValues[value.name] || value.values.length > 1) && (
                                                    <Facet onSelect={this.handleSelection} onDeselect={this.handleDeselection} facet={value} key={"facet-" + encodeURI(value.name)} />
                                                )}
                                            </>
                                        ))}
                                    </div>
                                )}
                            </div>

                            {products && products.length === 1 && (
                                <div className={styles.modalFooter}>
                                    <div className={styles.productsWrap}>
                                        <div className={styles.products}>
                                            {products.map((value, index) => (
                                                <div key={index} className={styles.productItem}>
                                                    <div className={styles.productItemButton}>
                                                        <div className={styles.button} onClick={this.handleInsertProduct(value.id, position, entryPosition, orientation)}>
                                                            <i className="fa far fa-plus"></i>
                                                        </div>
                                                    </div>
                                                    <div className={styles.productItemImage}>
                                                        <Product key={"product-" + index} id={value.id} />
                                                    </div>
                                                    <div className={styles.productItemName}>
                                                        {(this.hasSubModals() || this.isSubModal()) ? (
                                                            <ProductName key={"productname-" + index} id={value.id} showAccessories={false} />
                                                        ) : (
                                                            <ProductName key={"productname-" + index} id={value.id} showAccessories={true} selectedAccessories={selectedAccessories} onAddAccessory={this.handleAddAccessory} onRemoveAccessory={this.handleRemoveAccessory} />
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(ModalView));
