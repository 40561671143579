import React, { Component } from 'react';
import {connect} from "react-redux";

import * as ActionTypes from "../../actions";

const mapStateToProps = (state) => {
    return {
        locale: state.locale,
        facts: state.facts,
        products: state.products
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchProduct: (id) => dispatch(ActionTypes.fetchProduct(id))
    };
};

class Product extends Component {

    componentDidMount() {
        const {id} = this.props;
        this.props.fetchProduct(id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {id, locale} = this.props;

        if (prevProps.id && prevProps.id !== id) { // ID has changed ...
            this.props.fetchProduct(id);
        }
        if (prevProps.locale !== locale) { // locale has changed ...
            this.props.fetchProduct(id);
        }
    }

    showvalueReducer = showvalue => (accumulator, currentValue) => {
        if (currentValue.showvalues.includes(showvalue)) {
            accumulator = currentValue;
        }
        return accumulator;
    };

    blockValueByComparingAttributeValuesReducer = (values, showvalueToGet, showvalueToCompare, value) => (accumulator, currentValue) => {
        const showvalueToGetValue = currentValue.values?.reduce(this.showvalueReducer(showvalueToGet));
        const showvalueToCompareValue = currentValue.values?.reduce(this.showvalueReducer(showvalueToCompare));

        if (showvalueToCompareValue.value[0].value === value) {
            accumulator = showvalueToGetValue;
        }

        return accumulator;
    };

    getValue(node, showvalue) {
        return node?.values?.reduce(this.showvalueReducer(showvalue), null);
    }

    getBlockValueByComparingAttributeValues(block, showvalueToGet, showvalueToCompare, value) {
        return block?.value?.reduce(this.blockValueByComparingAttributeValuesReducer(block.value, showvalueToGet, showvalueToCompare, value), null);
    }

    getPublicImageUrl(asset) {
        const token = localStorage.getItem('xom_access_token');
        const href = asset?.value[0]?._links[2]?.href;

        return href?.replace('{?mimeType,width,height}', '?access_token=' + token);
    }

    render() {
        const {id, products, isAccessory} = this.props;
        const product = products[id] ? products[id] : false;

        const image = this.getValue(product, 'pk_produkt_bild');

        return product && (
            <img alt="" width={isAccessory ? '50' : '100'} src={this.getPublicImageUrl(image)} />
        );

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Product);
