import React, { Component } from 'react';
import {connect} from "react-redux";

const mapStateToProps = (state) => {
    return {
        snippets: state.snippets
    };
};
const mapDispatchToProps = (dispatch) => {
    return {};
};

class SnippetContent extends Component {

    htmlContent(html) {
        return {__html: html};
    }

    render() {
        const {id, fallback, snippets} = this.props;
        const snippet = snippets[id] ? snippets[id].content : fallback;

        return (
            <span dangerouslySetInnerHTML={this.htmlContent(snippet)}></span>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SnippetContent);
