import React, { Component } from 'react';

import styles from './RemoveButton.module.css';

class RemoveButton extends Component {

    render() {
        return (
            <div className={styles.removeButton}>
                <button className={styles.removeButtonToggle} onClick={this.props.onClick}>
                    <i className="fa far fa-trash"></i>
                </button>
            </div>
        );
    }
}

export default RemoveButton;
