import {applyMiddleware, createStore, compose} from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import * as ActionTypes from '../actions'
import rootReducer from '../reducers'

const persistConfig = {
    key: 'hess-configurators',
    storage,
    blacklist: ['faceting']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(persistedReducer, composeEnhancers(
    applyMiddleware(thunk)
));
store.dispatch(ActionTypes.boot());

const persistor = persistStore(store, {}, () => {
    store.dispatch(ActionTypes.fetchSnippets());
});

export { store, persistor };
