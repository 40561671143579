import React, { Component } from 'react';
import styles from "./Parameter.module.css";

import SnippetContent from '../containers/Partials/SnippetContent';

class Facet extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedValue: false
        };
    }

    componentDidMount() {
        const {selectedValue} = this.props;

        if (selectedValue) {
            this.setState({
                selectedValue: selectedValue
            })
        }
    }

    handleSelection = facet => event => {
        const value = event.target.value;

        this.setState({
            selectedValue: this.state.selectedValue === value ? false : value
        }, () => {
            if (this.state.selectedValue) {
                this.props.onSelect(facet, value);
            } else {
                this.props.onDeselect(facet);
            }
        });
    }

    renderValue(name) {
        if (name === "false" || name === "true") {
            if (name === "false") return "Nein";
            if (name === "true") return "Ja";
        }

        return name;
    }

    render() {
        const {facet} = this.props;
        const {selectedValue} = this.state;

        return (
            <div className={styles.parameter}>
                <h3 className={styles.parameterName}>
                    <SnippetContent id={facet.externalid + '_name'} fallback={facet.name} />
                </h3>
                <div className={styles.parameterValues}>
                    {facet.values.map((value) => (
                        <div className={styles.parameterItem} key={value.name}>
                            <input onChange={this.handleSelection(facet.name)} disabled={value.count === 0 || (selectedValue && value.filterQuery !== selectedValue)} checked={facet.count > 1 ? (value.filterQuery === selectedValue) : true} value={value.filterQuery} type="checkbox" name={encodeURI(facet.name)} id={"checkbox-" + encodeURI(value.name)}/>
                            <label htmlFor={"checkbox-" + encodeURI(value.name)}>
                                {this.renderValue(value.name)}
                            </label>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default Facet;
