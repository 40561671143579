import React, { Component } from 'react';

import styles from './ImageSelectTiny.module.css';

import SnippetContent from '../../containers/Partials/SnippetContent';

class ImageSelectTiny extends Component {

    render() {
        const {name, selections, selectedValue, onChange} = this.props;

        return (
            <>
                {Object.entries(selections).map(([key, value]) => (
                    <div className={styles.item} key={key}>
                        <input checked={selectedValue === key} onChange={onChange} value={key} type="radio" name={encodeURI(name)} id={"checkbox-" + encodeURI(name + value)}/>
                        <label htmlFor={"checkbox-" + encodeURI(name + value)}>
                            <img alt="" src={require('../../assets/parameters/' + key.replace('RuleEngine:', '') + '.jpg')} />
                            <span>
                                <SnippetContent id={'p_' + key.replace('RuleEngine:', '')} fallback={value} />
                            </span>
                        </label>
                    </div>
                ))}
            </>
        );
    }
}

export default ImageSelectTiny;
