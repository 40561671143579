import React, { Component } from 'react';

import CityResultImage from './CityResultImage';
import RenoResultImage from './RenoResultImage';

class ResultImage extends Component {

    components = {
        reno_elements: RenoResultImage,
        city_elements: CityResultImage
    }

    render() {
        const {configuratorId, navigate} = this.props;
        const ViewName = this.components[configuratorId];

        return (
            <ViewName navigate={navigate} />
        );
    }
}

export default ResultImage;
